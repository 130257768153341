import { combineReducers } from "@reduxjs/toolkit";
import experimentsReducer from "./experiments/slice";
import locationAutocompleteReducer from "./locationAutocomplete/slice";

export const reducer = combineReducers({
  experiments: experimentsReducer,
  locationAutocomplete: locationAutocompleteReducer,
});

export type ChangeForAnyReasonState = ReturnType<typeof reducer>;
