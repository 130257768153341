// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cZEvnDmHj7P-NAl1CBtNYg\\=\\={display:flex;flex-direction:column;row-gap:10px}.cZEvnDmHj7P-NAl1CBtNYg\\=\\= .destination-auto-complete .MuiAutocomplete-popperDisablePortal .MuiAutocomplete-paper .MuiAutocomplete-noOptions{padding:14px 16px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/ChangeForAnyReason/components/ChangeRoute/OriginDestinationSearch/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,YAAA,CACA,qBAAA,CACA,YAAA,CAMQ,8IACE,iBAAA","sourcesContent":[".multicity-location-pickers {\n  display: flex;\n  flex-direction: column;\n  row-gap: 10px;\n  // Weirdly the no option padding is not being applied to the destination due to some styling elsewhere in the app\n  :global {\n    .destination-auto-complete {\n      .MuiAutocomplete-popperDisablePortal {\n        .MuiAutocomplete-paper {\n          .MuiAutocomplete-noOptions {\n            padding: 14px 16px;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multicity-location-pickers": "cZEvnDmHj7P-NAl1CBtNYg=="
};
export default ___CSS_LOADER_EXPORT___;
