import { createSlice } from "@reduxjs/toolkit";
import type { CategorizedResponse } from "@b2bportal/air-shopping-api";

import { fetchLocationAutocomplete } from "./thunk";

type RequestState = "idle" | "pending" | "fulfilled" | "rejected";

export type LocationAutocompleteSliceState = {
  categories: CategorizedResponse[];
  status: RequestState;
};

const initialState: LocationAutocompleteSliceState = {
  categories: [],
  status: "idle",
};

export const locationAutocompleteSlice = createSlice({
  name: "locationAutocomplete",
  initialState,
  reducers: {
    resetSearch: (state) => {
      state.categories = [];
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLocationAutocomplete.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(fetchLocationAutocomplete.fulfilled, (state, action) => {
      state.categories = action.payload.categories;
      state.status = "fulfilled";
    });
    builder.addCase(fetchLocationAutocomplete.rejected, (state) => {
      state.status = "rejected";
    });
  },
});

export const { resetSearch } = locationAutocompleteSlice.actions;

export default locationAutocompleteSlice.reducer;
