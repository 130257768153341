import React from "react";
import { Box } from "@material-ui/core";
import type { Suggestion } from "@b2bportal/air-shopping-api";

import { B2BSearchAutocomplete, Icon, IconName } from "halifax";

import styles from "./styles.module.scss";
import { fetchLocationAutocomplete } from "../../../../../features/change-for-any-reason/reducers/locationAutocomplete/thunk";
import {
  selectAutoCompleteCategories,
  selectAutoCompleteIsLoading,
} from "../../../../../features/change-for-any-reason/reducers/locationAutocomplete/selectors";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../store";
import { resetSearch } from "../../../../../features/change-for-any-reason/reducers/locationAutocomplete/slice";

interface IOriginDestinationSearchProps {
  origin: Suggestion | null;
  setOrigin: (origin: Suggestion | null) => void;
  destination: Suggestion | null;
  setDestination: (destination: Suggestion | null) => void;
  hasMissingSearchInfoError?: boolean;
  disabled?: boolean;
}

export const OriginDestinationSearch = ({
  origin,
  setOrigin,
  destination,
  setDestination,
  disabled,
}: IOriginDestinationSearchProps) => {
  const dispatch = useDispatch();

  const fetchValueCategories = (query: string) => {
    dispatch(fetchLocationAutocomplete(query));
  };

  const resetAutoComplete = () => {
    dispatch(resetSearch());
  };

  const valueCategories = useAppSelector(selectAutoCompleteCategories);
  const isLoading = useAppSelector(selectAutoCompleteIsLoading);

  const getOptionSelected = (
    option: Suggestion | null,
    value: Suggestion | null
  ) =>
    !!value &&
    !!option &&
    value.id.Id === "Flight" &&
    option.id.Id === "Flight" &&
    value.id.code.code === option.id.code.code &&
    value.label === option.label;

  return (
    <Box className={styles["multicity-location-pickers"]}>
      <B2BSearchAutocomplete
        loading={isLoading}
        fetchValueCategories={fetchValueCategories}
        valueCategories={valueCategories}
        className="origin-auto-complete b2b"
        customIcon={
          <Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden={true} />
        }
        label="Where from?"
        value={origin}
        setValue={setOrigin}
        disabled={disabled}
        getOptionSelected={getOptionSelected}
        afterSetValue={resetAutoComplete}
      />
      <B2BSearchAutocomplete
        loading={isLoading}
        fetchValueCategories={fetchValueCategories}
        valueCategories={valueCategories}
        className="destination-auto-complete b2b"
        customIcon={
          <Icon name={IconName.B2BMapPin} ariaLabel="" aria-hidden={true} />
        }
        label="Where to?"
        value={destination}
        setValue={setDestination}
        disabled={disabled}
        getOptionSelected={getOptionSelected}
        afterSetValue={resetAutoComplete}
      />
    </Box>
  );
};
