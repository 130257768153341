import React, { useState } from "react";
import { IconName } from "halifax";
import type { Suggestion } from "@b2bportal/air-shopping-api";

import { EditTrigger } from "../EditTrigger";
import { RoutePickerModal } from "./RoutePickerModal";
import { SearchFlightRoute } from "../../../../features/change-for-any-reason/types";

interface ChangeRouteProps {
  originName: string;
  originCode: string;
  destinationName: string;
  destinationCode: string;
  updatedFlightRoute?: SearchFlightRoute;
  handleOnSave: (origin: Suggestion, destination: Suggestion) => void;
}

export const ChangeRoute: React.FC<ChangeRouteProps> = ({
  originName,
  originCode,
  destinationName,
  destinationCode,
  updatedFlightRoute,
  handleOnSave,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const selectedRoute = updatedFlightRoute
    ? `${updatedFlightRoute.origin.label}, ${updatedFlightRoute.destination.label}`
    : `${originName} (${originCode}), ${destinationName} (${destinationCode})`;

  return (
    <>
      <EditTrigger
        icon={IconName.B2BMapPin}
        title="Edit origin/destination"
        value={selectedRoute}
        onClick={() => setIsOpen(true)}
        isUpdated={updatedFlightRoute != null}
      />
      <RoutePickerModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        updatedFlightRoute={updatedFlightRoute}
        saveUpdateFlightRoute={handleOnSave}
      />
    </>
  );
};
