import React, { useCallback, useState } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  ActionButton,
  DateRangePicker,
  PickerType,
  useDeviceTypes,
} from "halifax";

import styles from "./styles.module.scss";
import { ModalWrapper } from "../../ModalWrapper";
import { DatePickerHeader } from "../DatePickerHeader";

interface DatePickerModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  savedStartDate: Date | null;
  saveUpdateFlightDates: (startDate: Date, endDate: Date | null) => void;
  savedEndDate: Date | null;
  pickerType: PickerType;
}
export const DatePickerModal: React.FC<DatePickerModalProps> = ({
  isOpen,
  setIsOpen,
  savedStartDate,
  savedEndDate,
  saveUpdateFlightDates,
  pickerType,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { matchesMobile } = useDeviceTypes();

  const datesSelected =
    startDate != null && (PickerType.DAY ? true : endDate != null);

  const handleClose = useCallback(
    (shouldSave = false) => {
      setIsOpen(false);
      if (datesSelected && shouldSave) {
        saveUpdateFlightDates(startDate, endDate);
      }
      setStartDate(null);
      setEndDate(null);
    },
    [datesSelected, endDate, saveUpdateFlightDates, startDate, setIsOpen]
  );

  const displayedStartDate = startDate ?? savedStartDate;
  // If the user has selected a new start date, we basically want to clear the endDate until they have selected a new local one
  const displayedEndDate = startDate ? endDate : endDate ?? savedEndDate;

  return (
    <ModalWrapper
      open={isOpen}
      onClose={() => handleClose()}
      modalClassName={clsx(styles["calendar-picker-popup-root"], {
        mobile: matchesMobile,
      })}
      headerElement={
        <DatePickerHeader
          showReturn={pickerType === PickerType.RANGE}
          selectedDates={[displayedStartDate, displayedEndDate]}
        />
      }
    >
      <DateRangePicker
        pickerType={pickerType}
        startDate={displayedStartDate}
        endDate={displayedEndDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        minAllowedDate={dayjs().toDate()}
        maxAllowedDate={dayjs().add(1, "year").toDate()}
        columnView={!!matchesMobile}
        months={[]}
        priceTags={[]}
        currency={""}
      />
      <ActionButton
        disabled={!datesSelected}
        data-test-id="save-dates-button"
        className={clsx(styles["save-dates-button"], {
          [styles["mobile"]]: matchesMobile,
        })}
        message="Save"
        onClick={() => handleClose(true)}
      />
    </ModalWrapper>
  );
};
