import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { ActionButton, useDeviceTypes } from "halifax";

import styles from "./styles.module.scss";
import { ModalWrapper } from "../../ModalWrapper";
import { RoutePickerHeader } from "../RoutePickerHeader";
import { Box } from "@material-ui/core";
import { OriginDestinationSearch } from "../OriginDestinationSearch";
import type { Suggestion } from "@b2bportal/air-shopping-api";
import type { SearchFlightRoute } from "../../../../../features/change-for-any-reason/types";

interface RoutePickerModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updatedFlightRoute?: SearchFlightRoute;
  saveUpdateFlightRoute: (origin: Suggestion, destination: Suggestion) => void;
}
export const RoutePickerModal: React.FC<RoutePickerModalProps> = ({
  isOpen,
  setIsOpen,
  saveUpdateFlightRoute,
}) => {
  const [origin, setOrigin] = useState<Suggestion | null>(null);
  const [destination, setDestination] = useState<Suggestion | null>(null);

  const { matchesMobile } = useDeviceTypes();

  const routesSelected = origin != null && destination != null;

  const handleClose = useCallback(
    (shouldSave = false) => {
      setIsOpen(false);
      if (routesSelected && shouldSave) {
        saveUpdateFlightRoute(origin, destination);
      }
    },
    [setIsOpen, routesSelected, saveUpdateFlightRoute, origin, destination]
  );

  return (
    <ModalWrapper
      open={isOpen}
      onClose={() => handleClose()}
      modalClassName={clsx(styles["route-picker-popup-root"], {
        mobile: matchesMobile,
      })}
      headerElement={<RoutePickerHeader />}
    >
      <Box className={styles["route-picker-container"]}>
        <OriginDestinationSearch
          origin={origin}
          setOrigin={setOrigin}
          destination={destination}
          setDestination={setDestination}
        />
      </Box>
      <ActionButton
        disabled={!routesSelected}
        data-test-id="save-route-button"
        className={clsx(styles["save-route-button"], {
          [styles["mobile"]]: matchesMobile,
        })}
        message="Save"
        onClick={() => handleClose(true)}
      />
    </ModalWrapper>
  );
};
