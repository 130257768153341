import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "../sagas";
import { rootReducer } from "../reducers";
import { TypedUseSelectorHook, useSelector } from "react-redux";

//REDUX SAGA middleware
export const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type TripsState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<TripsState> = useSelector;
