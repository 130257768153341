import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  ActiveExperiments,
  ChfarVariantsType,
} from "../../../../context/experiments";

export type ChFARExperimentSliceState = {
  values: {
    [ActiveExperiments.CHFAR]: ChfarVariantsType;
  };
};

export type ActiveChFARExperiments = keyof ChFARExperimentSliceState;

const initialState = {
  values: {
    [ActiveExperiments.CHFAR]: "control",
  },
};

const chfarExperimentsSlice = createSlice({
  name: "chfarExperiments",
  initialState,
  reducers: {
    setExperiments(
      experiments,
      action: PayloadAction<Partial<ChFARExperimentSliceState["values"]>>
    ) {
      experiments.values = { ...experiments.values, ...action.payload };
    },
  },
});

export const { setExperiments } = chfarExperimentsSlice.actions;

export default chfarExperimentsSlice.reducer;
