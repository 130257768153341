import { createSelector } from "@reduxjs/toolkit";
import { LocationAutocompleteSliceState } from "./slice";

export type ChFARLocationAutocompleteState = {
  changeForAnyReason: {
    locationAutocomplete: LocationAutocompleteSliceState;
  };
};

export const selectAutoCompleteStatus = (
  state: ChFARLocationAutocompleteState
) => state.changeForAnyReason.locationAutocomplete.status;

export const selectAutoCompleteIsLoading = createSelector(
  [selectAutoCompleteStatus],
  (status) => status === "pending"
);
export const selectAutoCompleteCategories = (
  state: ChFARLocationAutocompleteState
) => state.changeForAnyReason.locationAutocomplete.categories;
